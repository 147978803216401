@use 'news_module_commons' as *;

.news-module {
  margin: 50px 0 40px;
  background: var(--c_grey_100);

  .wrapper {
    & > .flex {
      flex-direction: column;
    }
  }

  .block {
    margin-bottom: 24px;
  }
}
