@use 'news_module_item_commons' as *;
@use '../../../shared/info_item/info_item_mobile';

.news-module {
  .info-items {
    padding: 0;
  }

  .link-cnt {
    cursor: pointer;
  }
}
