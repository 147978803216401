@use 'special_offers_banner_commons' as *;

.special-offers-banner {
  position: relative;

  .block-title-header {
    .block-title {
      font-size: 24px;
    }

    .special-offers-all {
      @include button;
      position: absolute;
      bottom: 0;
      z-index: 1;
      margin-bottom: 24px;
      background-color: var(--c_primary);
      color: var(--c_white);

      &::before {
        display: none;
      }
    }
  }

  .offers-container {
    position: relative;
    height: auto;
    padding: 0;
    padding-bottom: 0;
    overflow: visible;
    background: var(--c_white);
    background: linear-gradient(180deg, var(--c_white) 0%, var(--c_white) 25%,
    var(--c_grey_100) 25%);

    .container.wrapper {
      width: auto;
      padding: 0 0 20px 20px;
    }

    .offers-container--swiper {
      display: flex;
      padding-bottom: 16px;
      margin-bottom: 0;
    }

  }

  .offer-card {
    position: relative;
    display: flex;
    flex: 1 0 80%;
    width: 80%;
    margin-right: 16px;
  }

  .offer-card:last-child {
    margin-right: 16px;
  }

  .offer-card > .offer-banner {
    height: 100%;
  }

  .offer-banner--container {
    background: transparent;

    .offer-banner--image{
      height: auto;
      margin-bottom: 4px;
      aspect-ratio: 1/1;

      .image {
        width: 100%;
        height: auto;
      }

      img {
        transform: none !important;
        aspect-ratio: 1/1;
      }
    }

    .offer-banner--data {
      position: static;
      transform: none;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0;
      background-color: transparent;

      .visible-text {
        margin-bottom: 8px;

        .content {
          margin-bottom: 0;
        }
      }

      .content.t-highlight {
        @include fontSerifSemiBold;
        padding: 0;
        margin: 0;
        color: var(--c_blue_700);
        line-height: 1.5em;
      }
    }
  }

  .special-offers-all.inline {
    display: none;
  }
}
