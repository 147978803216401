@use 'slide_cards_commons' as *;

.slide-cards {
  .overlay-wrapper {
    height: 80%;

    .overlay {
      display: flex;
      flex-direction: column;
      padding: 16px;

      .block-top {
        display: inline;
        flex-direction: column;
        justify-content: flex-end;

        .subtitle {
          display: inline;
          margin-top: 8px;
          margin-bottom: 32px;
        }
      }
    }
  }

  .heading {
    text-align: left;
  }

  .dropdown {
    font-weight: bold;
    font-size: 1rem;

    .s {
      padding-left: 0.2rem;
    }
  }

  .slide-cards-items-cnt {
    height: 100%;
    padding: 20px 0 10px;
    margin-bottom: 40px;
    background-color: var(--c_white);

    .container {
      .ss-slider {
        padding-bottom: 32px;
        overflow-y: hidden;

        .ss-slide {
          &:hover {
            picture {
              transform: none !important;
              transition: none !important;
            }
          }
        }
      }

    }

    .items {
      .ss-slider {
        justify-content: flex-start;

        .ss-slide {
          place-content: flex-start flex-start;
        }
      }
    }

    .navigation {
      display: none;
    }
  }
}
